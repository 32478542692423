@import "../base/_variables.scss";

.button-form {
  border: none;
  padding: 10px 50px;
  margin: 10px 5px 10px 0px;
  cursor: pointer;
  width: -webkit-fill-available;
  background-color: $button-color;
  color: white;
}
