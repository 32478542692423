.container-summary {
  flex: 1;
  .summary-item {
    background-color: #28282896;
    color: white;

    border-radius: 5px;
    margin-bottom: 5px;
    .icone {
      color: white;
    }
    .summary-title {
      box-shadow: 0px 0px 11px 0px black;
      font-size: 24px;
      span {
        margin-inline: 5px 40px;
      }
    }
    .summary-list {
      li {
        list-style-type: disc;
      }
    }
  }
}

@media (max-width: 740px) {
  .container-summary {
    div {
      div {
        font-size: 0.72em;
      }
    }
  }
}
