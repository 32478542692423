.book-add {
  .back-nav {
    span {
      cursor: pointer;
    }
  }
  h1 {
    text-align: center;
  }
  div {
    div {
      color: white;
      label {
        color: white;

        &:focus {
          color: white;
        }
      }
    }
  }

  button {
    border: none;
    padding: 10px 50px;

    cursor: pointer;
    width: -webkit-fill-available;
  }
  .input-page {
    .input-file {
      border: 1px solid rgb(149 143 143 / 71%);
      width: 100%;
      margin: 10px 10px 0px 0px;
    }
  }
}

@media (max-width: 800px) {
  .news {
    h1 {
      font-size: 1.75em;
    }
    div {
      &:nth-child(3) {
        display: flex;
        flex-direction: column;

        :first-child {
          display: flex;
        }
      }
      .input-page {
        .input-file {
          margin-right: 0px;
        }
      }
    }
  }
}

@media (max-width: 511px) {
  .news {
    h1 {
      font-size: 1.75em;
    }
    div {
      &:nth-child(3) {
        display: flex;
        flex-direction: column;

        :first-child {
          display: flex;
        }
      }
      .input-page {
        .input-file {
          margin-right: 0px;
        }
      }
    }
  }
}
