.delete {
  .header-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-item-header {
    width: 100%;
    h1 {
      text-align: center;
    }
  }
  .form-item {
    height: 400px;
    overflow: hidden;

    .container-itens-edit {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .delete {
    .form-item-header {
      width: 100%;
      h1 {
        text-align: center;
      }
    }
    .container-itens-edit {
      .itens-render {
        h2 {
          font-size: 1.25em;
        }
      }
    }
  }
}
