@media (max-width: 511px) {
  .edit {
    .form-item {
      padding-inline: 20px;
    }

    .container-itens-edit {
      .itens-render {
        h2 {
          font-size: 1.25em;
        }
        .edit-div {
          display: flex;
        }
      }
    }
  }
}
