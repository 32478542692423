@import "../../../../assets/styles/base/variables";

.Card {
  width: auto;
  gap: 25px;
  margin: 10px;
  background-color: black;
  border-radius: 30px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 0px 8px 0px white;
}

@media (max-width: 1080px) {
  .Card {
    gap: 15px;
    margin-inline: 1px;
  }
}

@media (max-width: 1006px) {
  .Card {
    font-size: 0.75em;
  }
}

@media (max-width: 886px) {
  .Card {
    margin-inline: 8px;
  }
}

@media (max-width: 640px) {
  .Card {
    margin-inline: 7px;
  }
}

@media (max-width: 540px) {
  .Card {
    margin-inline: 3px;
    font-size: 0.75em;
  }
}
