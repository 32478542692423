.form {
  .add {
    .alert {
      div {
        border: inherit;
      }
    }
    .input-page {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .input-file {
        width: 200px;
        height: 58px;
        margin: 8px;
        display: flex;
        input {
          width: 100%;
          align-content: center;
        }
      }
    }
  }
  .error {
    div {
      div:nth-child(2) {
        color: black;
      }
    }
  }
  .alert-error {
    div {
      div {
        div {
          border: none;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .form {
    .add {
      .input-page {
        .input-file {
          width: 224px;
        }
      }
    }
  }
}
