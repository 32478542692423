@import "../../assets/styles/base/variables";

.container-menu {
  position: absolute;
  top: 212px;
  left: 12px;
  width: 155px;
  padding-top: 10px;
  margin-right: 10px;
  color: $text-color;
  font-size: 32px;

  .menu-background {
    position: relative;
    top: 14px;

    .menu-interaction {
      cursor: pointer;
      padding-top: 10px;
    }
    .hidden {
      img {
        display: none;
      }
      display: none;
    }
    .close {
      display: none;
      width: max-content;
      animation: display 1000ms ease-in-out;
    }

    .close.active {
      display: inherit;
    }
    .menu-itens {
      width: 145px;
      display: none;
      background-color: #07080c;

      border-radius: 30px;
      border: 1px solid white;
      margin: -10px 10px;
      font-size: 20px;

      ul {
        padding-left: 20px;
      }
      li {
        margin-block-end: 10px;
        a {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    .menu-itens.active {
      display: inherit;
    }
  }
}

@media (max-width: 1100px) {
  .container-menu {
    top: 165px;
    left: 8px;
    width: inherit;

    .menu-background {
      .menu-interaction {
        img {
          width: 60px;
        }
      }

      .menu-itens {
        width: inherit;
        padding-inline: 2px 8px;
        ul {
          padding-left: 0px;
        }
      }

      .close {
        img {
          width: 30px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .container-menu {
    position: fixed;
    top: inherit;
    left: inherit;
    bottom: 0px;
    width: 100%;
    z-index: 100;
    .menu-background {
      .menu-interaction {
        display: none;
      }

      .menu-itens {
        display: flex;
        width: 100%;
        height: 80px;
        margin: 0px;
        border-radius: 0px;
        border: none;
        background-color: #696969;

        ul {
          width: 100%;
          align-self: center;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
  }
}
