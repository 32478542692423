@import "../../assets/styles/base/variables";

.wall-title {
  text-align: center;
  color: $text-color;

  h2 {
    margin-block: 0px;
    height: 71px;
    font-size: 2em;
    align-content: center;
  }
}

.container-wall-content {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.wall-content-image {
  height: max-content;
  width: -webkit-fill-available;
  margin-bottom: 50px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  padding-inline: 20px;

  .wall-content {
    margin-inline-end: 10px;
    h3 {
      text-align: center;
      margin-block: 0px;
      font-size: 1.5em;
    }

    .wall-scroll {
      p {
        column-count: 2;
        column-gap: 10px;
        padding: 10px;
      }
    }
  }

  .wall-image {
    flex: 1;
    img {
      width: 100%;
      // height: 100%;
      border-radius: 10px;
      border: solid;
    }
  }
}

.wall-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  align-items: center;
}

.wall-index-item {
  margin-block: 10px;
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .wall-index-div {
    max-width: 300px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    .wall-index-item-div {
      display: flex;
      width: max-content;

      .index-item {
        width: 16px;
        height: 16px;
        margin-inline: 10px;
        background-color: rgba(255, 255, 255, 0.61);
        border-radius: 100%;
      }

      .index-item.active {
        background-color: white;
      }
    }
  }
}

@media (max-width: 1100px) {
  .wall-content-image {
    padding: 0px;
    margin-inline: 20px;
  }
}

@media (max-width: 900px) {
  .wall-title {
    h2 {
      font-size: 1.5em;
    }
  }
  .wall-content-image {
    grid-template-columns: 1fr;
    .wall-content {
      h3 {
        font-size: 1.25em;
      }
    }
  }
}

@media (max-width: 670px) {
  .wall-title {
    h2 {
      font-size: 1.25em;
    }
  }
  .wall-content-image {
    grid-template-columns: 1fr;
    .wall-content {
      font-size: 0.75em;
      h3 {
        font-size: 1.1em;
      }
    }
  }
}

@media (max-width: 600px) {
  .container-wall-content {
    .wall-content-image {
      gap: 50px;
    }
  }
}

@media (max-width: 500px) {
  .wall-title {
    h2 {
      font-size: 0.9em;
    }
  }

  .container-wall-content {
    .wall-content {
      font-size: 0.7em;
      h3 {
        font-size: 0.8em;
      }
    }
  }
}
