@import "../../assets/styles/base/variables";

.container-header {
  display: flex;
  align-items: center;
  margin-block: 38px 31px;
  margin-inline: 17px;

  .logo {
    width: 150px;
    height: 150px;
    border: solid 1px white;
    border-radius: $radius_secondary;
    background-image: url("../../assets/images/logo.jpg");
    background-size: cover;
    background-position: center;
  }

  .header-title {
    margin-left: 10px;
    flex: 1;
    background-color: $primary-color;
    border: 1px solid white;
    border-radius: $radius_secondary;
    padding: 50px;

    h1 {
      font-size: 45px;
      color: $text-color;
      text-align: center;
      margin: inherit;
    }
  }
}

@media (max-width: 1100px) {
  .container-header {
    margin-block: 40px 20px;

    .logo {
      width: 110px;
      height: 110px;
    }

    .header-title {
      padding: 40px;
      h1 {
        font-size: 2em;
      }
    }
  }
}

@media (max-width: 900px) {
  .container-header {
    margin-block: 20px;

    .logo {
      width: 90px;
      height: 90px;
      border-radius: 20px;
    }

    .header-title {
      padding: 30px 15px;
      border-radius: 20px;
      h1 {
        font-size: 1.75em;
      }
    }
  }
}

@media (max-width: 667px) {
  .container-header {
    margin-block: 10px;
    margin-inline: 5px;

    .logo {
      width: 85px;
      height: 85px;
    }

    .header-title {
      padding: 0px;
      height: 80px;
      margin-left: 5px;
      align-content: center;
      h1 {
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 540px) {
  .container-header {
    .logo {
      width: 75px;
      height: 75px;
    }
    .header-title {
      h1 {
        font-size: 1em;
      }
    }
  }
}

@media (max-width: 400px) {
  .container-header {
    .header-title {
      padding-inline: 0px 2px;
      h1 {
        font-size: 0.89em;
        text-align: center;
      }
    }
  }
}
