.container-home {
  margin-inline: 17px;
  margin-bottom: 10px;

  .container-home-content {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 900px) {
  .container-home {
    margin-bottom: 80px;
  }
}
@media (max-width: 667px) {
  .container-home {
    margin-inline: 5px;
  }
}
