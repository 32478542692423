.container-form {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-item {
    background-color: #07080c;
    padding: 43px;
    border: 1px solid white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .item {
      margin: 10px;
      display: flex;
      align-items: center;
      label {
        margin: 10px;
        font-size: 25px;
      }
      input,
      textarea {
        height: 20px;
        border: none;
        border-radius: 20px;
        background-color: #272729;
        color: white;
        padding: 10px;
      }
    }

    .form-button {
      padding: 10px 50px;
      border-radius: 30px;
      border: none;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
