.primary {
  background-color: transparent;
  border: none;
  width: 100px;
  cursor: pointer;
  color: white;

  &:where(:disabled) {
    opacity: 0.3;
  }
}
