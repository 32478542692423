@import "../base/variables";

.container-itens {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $primary-color;
  border-radius: $radius_secondary;
  border: 1px solid white;
  margin-inline-start: 162px;

  // .container-itens {
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;
  //   background-color: rgba(4, 4, 4, 0.7411764706);
  //   border-radius: 30px;
  //   border: 1px solid white;
  //   margin-inline-start: 162px;
  //   position: relative;
  // }
}

@media (max-width: 1100px) {
  .container-itens {
    margin-inline-start: 120px;
  }
}

@media (max-width: 900px) {
  .container-itens {
    margin-inline-start: 0px;
    border-radius: 20px;
  }
}

@media (max-width: 600px) {
  .container-itens {
    gap: 30px;
  }
}
