.box-border {
  div {
    div {
      border: 1px solid rgba(255, 255, 255, 0.281);
      color: white;
      label {
        color: white;
      }
      &:disabled {
        span {
          color: inherit;
        }
      }
    }
  }

  .input-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: inherit;
    .input-file {
      width: 200px;
      height: 58px;
      margin: 8px;
      display: flex;
      input {
        width: 100%;
        align-content: center;
      }
    }
  }
  .text-field {
    div {
      width: -webkit-fill-available;
    }
    margin-block: 15px;
  }
}
