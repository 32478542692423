@media (max-width: 600px) {
  .book-edit {
    .form-item {
      .container-itens-edit {
        .itens-render {
          .book-edit-div {
            display: flex;
            flex-direction: column;
            div {
              margin-right: 0px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
