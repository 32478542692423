@import "../../../assets/styles/base/variables";

.container-admin {
  width: 90vw;
  height: max-content;
  padding-inline: 20px;
  background-color: $primary-color;
  border-radius: $radius_secondary;
  border: 1px solid white;
  margin: 2.1% auto;
  text-align: center;

  .container-cards {
    margin: auto;
    max-width: 90%;
    display: grid;
    margin-block: 5%;
    grid-template-columns: repeat(3, 1fr);
  }

  .logout {
    text-align: end;
    padding: 10px 70px;
    span {
      cursor: pointer;
    }
  }
}

@media (max-width: 1173px) {
  .container-admin {
    .container-cards {
      width: 100%;
      max-width: inherit;
      justify-content: space-between;
    }
  }
}

@media (max-width: 886px) {
  .container-admin {
    padding: 10px;
    h1 {
      font-size: 1.5em;
    }
    .container-cards {
      justify-content: center;
    }

    .logout {
      padding: 10px 20px;
    }
  }
}

@media (max-width: 540px) {
  .container-admin {
    .container-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
