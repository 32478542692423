@import "../../assets/styles/base/variables";

.book-title {
  display: flex;
  justify-content: center;
}

.book-content-image {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .book-image {
    border: 3px solid white;
  }

  .book-summary {
    max-width: 500px;
    h2 {
      font-size: 1.7em;
    }

    p {
      font-size: 1.25em;
      text-align: justify;
    }
  }
  .book-description,
  span {
    display: flex;
    align-items: center;

    span {
      width: 70px;
    }

    .book-chapters {
      position: relative;

      .book-list {
        cursor: pointer;
      }

      ul {
        position: absolute;
        top: 15px;
        left: 3px;
        width: 100px;
        border: 1px solid white;
        display: none;
        flex-wrap: wrap;
        font-size: 18px;
        background-color: $primary-color;

        li {
          margin-right: 6px;
          cursor: pointer;
          text-decoration-line: underline;
        }
      }
      .active {
        display: flex;
      }
    }
  }

  .title-summary {
    h2 {
      margin-bottom: 10px;
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .sumarioIndice {
    width: 90%;
  }
}

@media (max-width: 940px) {
  .book-content-image {
    gap: 10px;
    padding-inline: 10px;
    .book-summary {
      h2 {
        font-size: 1.5em;
      }

      p {
        font-size: 1em;
        text-align: justify;
      }
    }
  }
}

@media (max-width: 780px) {
  .book-content-image {
    .book-summary {
      width: 345px;
      h2 {
        font-size: 1.25em;
      }

      p {
        font-size: 1em;
        text-align: justify;
        margin-top: 0px;
      }
    }
  }
}

@media (max-width: 620px) {
  .book-content-image {
    .book-summary {
      width: 345px;
      .title-summary {
        h2 {
          font-size: 1em;
        }
        span {
          font-size: 0.75em;
          svg {
            width: 20px;
            margin-right: 1px;
          }
        }
      }

      p {
        font-size: 0.75em;
        text-align: justify;
        margin-top: 0px;
      }
    }
  }
}

@media (max-width: 500px) {
  .book-title {
    h2 {
      font-size: 1em;
    }
  }
  .book-content-image {
    flex-direction: column;
    justify-content: center;

    .book-image {
      border: 3px solid white;
    }
    .book-summary {
      width: 95%;

      .title-summary {
        span {
          svg {
            width: 15px;
            margin-right: 1px;
          }
        }
      }
    }
  }
}
