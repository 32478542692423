.edit {
  .form-item {
    height: 500px;
    width: 500px;
    display: flex;
    justify-content: flex-start;
    padding: 10px 40px;
    overflow: hidden;

    .form-item-header {
      margin-top: 10px;
      width: 100%;
      div {
        cursor: pointer;
      }
      h1 {
        margin-bottom: 10px;
        text-align: center;
      }
    }
    .container-itens-edit {
      overflow-y: scroll;
      width: -webkit-fill-available;

      .itens-render {
        background-color: black;
        color: white;

        & > div {
          border: 1px solid white;
          cursor: pointer;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .form-inputs {
      form {
        div {
          div {
            div {
              border: 1px solid #ffffff40;
              color: white;
            }
          }
          label {
            color: white;
          }
        }

        .text-field {
          div {
            width: -webkit-fill-available;
          }
        }

        .button-form {
          border: none;
          padding: 10px 50px;
          margin: 10px;
          cursor: pointer;
          width: -webkit-fill-available;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .edit-page {
    .active {
      overflow-y: scroll;
      .modal {
        padding: 0px;
        border: none;
        width: 100%;
        height: 100%;
        background-color: black;
        .box-border {
          .box-border-edit {
            div {
              width: 100%;
            }
            .page-edit-mobile {
              border: none;
              display: flex;
            }
          }

          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}
