.container-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login {
    background-color: #07080c;
    padding: 50px;
    border: 1px solid white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-item {
      margin: 10px;
      display: flex;
      align-items: center;
      label {
        margin: 10px;
        font-size: 25px;
      }
      input {
        height: 20px;
        border: none;
        border-radius: 20px;
        background-color: #272729;
        color: white;
        padding: 10px;
      }
    }

    .Login-button {
      padding: 10px 50px;
      border-radius: 30px;
      border: none;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}

@media (max-width: 700px) {
  .container-login {
    .login {
      padding: 10px;

      .login-item {
        label {
          font-size: 0.75em;
        }
        input {
          padding: 5px;
          border-radius: 10px;
        }
      }

      .Login-button {
        padding: 5px 40px;
      }
    }
  }
}
