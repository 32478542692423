.container-page {
  background-color: black;
  position: relative;
  .wall-title {
    text-align: start;
    margin: 27px;

    h2 {
      height: max-content;
    }

    p {
      margin-block: 0px;
    }
  }
  .container-wall-content {
    display: flex;
    width: 95%;
    justify-content: space-around;
    margin: 20px auto;

    .wall-content .wall-scroll p {
      column-count: 1;
      text-align: justify;
    }

    .wall-content-image {
      grid-template-columns: 2fr 1fr;
      align-items: center;
      line-height: 1.3em;
    }
  }

  .wall-content-all {
    display: flex;

    .wall-content .wall-scroll p {
      column-count: 1;
      text-align: justify;
      padding-inline: 20px;
    }
  }
  .float-Button {
    position: absolute;
    height: 100%;
    display: flex;
    border-radius: 30px 0 0 30px;
    opacity: 0.5;

    &:where(:hover) {
      background-color: rgba(7, 7, 7, 0.795);
      opacity: 1;
    }
  }

  .left {
    &:hover {
      box-shadow: 5px 0px 10px 1px black;
    }
  }
  .right {
    right: 0;
    &:hover {
      box-shadow: -5px 0px 10px 1px black;
    }
  }
}

.button-index-page {
  margin-block: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .primary {
    display: none;
    width: max-content;
  }

  .wall-index-item {
    max-width: 50%;
  }
}

@media (max-width: 700px) {
  .container-page {
    .container-wall-content {
      .wall-content-image {
        grid-template-columns: 1fr;
        margin-inline: 10px;

        .wall-content {
          order: 2;
          margin-inline-end: 0px;

          .wall-scroll {
            overflow-y: hidden;
            p {
              padding: 0px;
              text-align: justify;
              column-count: 1;
            }
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
        .wall-image {
          order: 1;
          height: 400px;
          img {
            object-fit: contain;
          }
        }
      }
    }

    .float-Button {
      display: none;
    }
    .button-index-page {
      .primary {
        display: inherit;
      }
    }
  }
}

@media (max-width: 490px) {
  .container-page {
    .container-wall-content {
      .wall-content-image {
        .wall-image {
          img {
            object-fit: inherit;
          }
        }
      }
    }
  }
}
