.input-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .input-file {
    width: 200px;
    height: 58px;
    margin: 8px;
    display: flex;
    input {
      width: 100%;
      align-content: center;
    }
  }
}
