.universe {
  padding: 50px 20px 100px 20px;
  text-align: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;

    & > div {
      margin: 20px 10px;
    }
  }

  .button-img {
    position: absolute;
    top: 35%;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
}

@media (max-width: 1100px) {
  .universe {
    h1 {
      font-size: 1.75em;
    }
    .item {
      flex-wrap: wrap;

      & > div {
        width: 300px;
        height: 600px;

        & > div {
          padding: 8px;
        }
        img {
          height: 400px;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .universe {
    h1 {
      font-size: 1.25em;
    }
    .item {
      & > div {
        width: 250px;
        height: 600px;

        div {
          p {
            font-size: 0.75em;
          }
        }
      }
    }
  }
}

@media (max-width: 590px) {
  .universe {
    .item {
      & > div {
        width: 200px;

        div {
          p {
            font-size: 0.75em;
          }
        }
        bottom: 10px;
        top: inherit;
      }
    }
  }
}
