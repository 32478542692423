@import "./variables";

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-image: url("../../images/fundo.jpg");
  background-size: contain;
  background-attachment: fixed;
  color: white;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: $primary-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: $radius_primary;
  }
}

a,
li {
  list-style-type: none;
  text-decoration: none;
  color: white;
}
p {
  white-space: pre-wrap;
}
